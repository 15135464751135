import BaseService from '@src/services/BaseService'

class MixesService extends BaseService {
    constructor() {
        super('mixes')
    }

    deleteMultiple(params) {
        return this.axios.post(`${this.getUrl()}/delete-multiple`, params)
    }

    assign(id, params) {
        return this.axios.post(`${this.getUrl()}/${id}/assign`, params)
    }

    detach(id, params) {
        return this.axios.post(`${this.getUrl()}/${id}/detach`, params)
    }

    sync(id, params) {
        return this.axios.post(`${this.getUrl()}/${id}/sync`, params)
    }
}

export default new MixesService()
