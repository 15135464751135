<template>
    <data-modal
        ref="dataModal"
        :fetch-data="fetch"
        :title="titleFormatted"
        :fields="fields"
        :additional-parameters="additionalParameters"
        :sort-order="sortOrder"
        :search-fields="searchFields"
        :per-page="perPage"
        size="xl"
    >
    </data-modal>
</template>

<script>
import DataModal from '@/src/components/dataModal'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import productFields from '@src/fields/productFields'
import ProductsService from '@/src/services/ProductsService'

export default {
    components: {
        DataModal,
    },
    mixins: [
        vuetableFormatters,
        productFields
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            data: [],
            perPage: 10,
            mixId: 0,
            mixName: '',
        }
    },
    computed: {
        fields() {
            return this.productFields.filter(
                (field) => (field.name !== 'actions') && (field.name !== 'photo') && (typeof field.name !== 'object')
            )
        },
        additionalParameters() {
            return {
                with: ['category', 'brand'],
                mix_id: this.mixId,
            }
        },
        searchFields() {
            return ['name', 'reference', 'brand.name', 'category.name']
        },
        i18nProducts() {
            return this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    })
        },
        titleFormatted() {
            if (this.mixName) {
                return `${this.i18nProducts} (${this.mixName})`
            } else {
                return this.i18nProducts
            }
        },
    },
    methods: {
        fetch(url, params) {
            return ProductsService.fetchVuetable(url, params)
        },
        showModal(id, name) {
            this.mixId = id
            this.mixName = name
            this.$nextTick(() => {
                this.$refs.dataModal.show()
            })
        },
        hideModal() {
            this.$refs.dataModal.hide()
        },
    },
}
</script>
