<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalMix"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        :size="tabIndex === 0 ? 'lg' : 'xl'"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nMix"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nMix"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'MIX')"
                                :title="getI18n(i18nKey, 'TITLES.mix')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'MIX')"
                                :title="getI18n(i18nKey, 'TITLES.mix')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="teams-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="8">
                                <b-form-group
                                    :label="i18nName"
                                    class="required label-pdv"
                                    label-for="modal-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.modalMix.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        autofocus
                                        autocomplete="off"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('name', 'modalMix')"
                                        @blur="$v.modalMix.name.$touch"
                                        @input="clearResponseError('name', 'modalMix')"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nExternalCode"
                                    class="required label-pdv"
                                    label-for="externalCode-input"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_external_code')"
                                >
                                    <b-form-input
                                        id="externalCode-input"
                                        v-model="$v.modalMix.external_code.$model"
                                        class="input-pdv-blue"
                                        name="externalCode"
                                        autocomplete="off"
                                        :state="validateField('external_code', 'modalMix')"
                                        :disabled="!isEdit || submitLoading"
                                        @blur="$v.modalMix.external_code.$touch"
                                        @input="clearResponseError('external_code', 'modalMix')"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="i18nBusinessUnit"
                                    class="required label-pdv"
                                    label-for="modal-business-unit"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_business_unit')"
                                >
                                    <multi-select-with-service
                                        :id="'modal-business-unit'"
                                        ref="businessUnitMultiselect"
                                        v-model="modalMix.business_unit"
                                        :input-function="$v.modalMix.business_unit.id.$touch"
                                        :select-class="validationClass($v.modalMix.business_unit.id)"
                                        :service="'business-units'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="isMixCreated || !isEdit || submitLoading"
                                        @input="changeBusinessUnitId"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="i18nType"
                                    label-for="modal-mixable-type"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                                >
                                    <translated-multiselect
                                        id="modal-mixable-type"
                                        v-model="mixableType"
                                        :options="mixableTypes"
                                        :disabled="isMixCreated"
                                        track-by="value"
                                        label="text"
                                        :preselect-first="true"
                                        @input="changeMixableType"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col v-show="modalMix.mixable_type" md="6">
                                <b-form-group
                                    :label="i18nMixable"
                                    class="required label-pdv"
                                    label-for="modal-mixable"
                                    :invalid-feedback="invalidMixable"
                                >
                                    <multiSelectWithService
                                        v-show="modalMix.mixable_type === 'point_of_sale'"
                                        :id="'modal-mixable'"
                                        ref="mixableMultiselect"
                                        v-model="modalMix.mixable"
                                        :input-function="$v.modalMix.mixable.id.$touch"
                                        :select-class="validationClass($v.modalMix.mixable.id)"
                                        :service="'pos'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="isMixCreated || typeChanged || !isEdit || submitLoading"
                                        @input="changeMixableId"
                                    />
                                    <multiSelectWithService
                                        v-show="modalMix.mixable_type === 'point_of_sale_chain'"
                                        :id="'modal-mixable'"
                                        ref="mixableMultiselect"
                                        v-model="modalMix.mixable"
                                        :input-function="$v.modalMix.mixable.id.$touch"
                                        :select-class="validationClass($v.modalMix.mixable.id)"
                                        :service="'pos-chains'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="isMixCreated || typeChanged || !isEdit || submitLoading"
                                        @input="changeMixableId"
                                    />
                                    <multiSelectWithService
                                        v-show="modalMix.mixable_type === 'point_of_sale_channel'"
                                        :id="'modal-mixable'"
                                        ref="mixableMultiselect"
                                        v-model="modalMix.mixable"
                                        :input-function="$v.modalMix.mixable.id.$touch"
                                        :select-class="validationClass($v.modalMix.mixable.id)"
                                        :service="'pos-channels'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="isMixCreated || typeChanged || !isEdit || submitLoading"
                                        @input="changeMixableId"
                                    />
                                    <multiSelectWithService
                                        v-show="modalMix.mixable_type === 'point_of_sale_regional'"
                                        :id="'modal-mixable'"
                                        ref="mixableMultiselect"
                                        v-model="modalMix.mixable"
                                        :input-function="$v.modalMix.mixable.id.$touch"
                                        :select-class="validationClass($v.modalMix.mixable.id)"
                                        :service="'pos-regionals'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="isMixCreated || typeChanged || !isEdit || submitLoading"
                                        @input="changeMixableId"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <b-spinner
                                v-if="loadingProducts"
                                class="mr-1"
                                type="border"
                                small
                            />
                            {{ i18nProducts }}
                        </template>
                        <b-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nProducts"
                                    label-for="add-product-input"
                                    class="label-pdv">
                                    <multi-select-with-service
                                        :id="'add-product-input'"
                                        ref="productMultiselect"
                                        v-model="productName"
                                        :select-class="validationClass($v.modalMix.products)"
                                        :service="'products'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!productsLoaded || loadingProducts || submitLoading"
                                        :parameters="productsParameters"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalMix.products"
                                    :fields="productChildrenActionFields"
                                    :display-delete-button="isEdit"
                                    :busy="loadingProducts"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveProduct"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex === 0"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isMixCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    :disabled="!productsLoaded"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    :disabled="!productsLoaded"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import {
    required,
    requiredIf,
    minLength,
    maxLength,
    numeric,
    minValue,
} from 'vuelidate/lib/validators'

import multiSelectWithService from '@src/components/multiSelectWithService'
import translatedMultiselect from '@src/components/translatedMultiselect'
import MixesService from '@src/services/MixesService'
import ProductsService from '@src/services/ProductsService'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import filterVuetable from '@src/mixins/filterVuetable'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import posChildrenActionFields from '@/src/fields/childrens/posChildrenActionFields.vue'
import productChildrenActionFields from '@/src/fields/childrens/productChildrenActionFields.vue'
import mixTypes from '@src/mixins/mixTypes'

const i18nCommon = 'COMMON'
const i18nKey = 'MIXES'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        multiSelectWithService,
        translatedMultiselect,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        DataModeVuetable
    },
    mixins: [
        validationMixin,
        swalFeedback,
        filterVuetable,
        posChildrenActionFields,
        productChildrenActionFields,
        mixTypes
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    name: '',
                    external_code: '',
                    business_unit_id: null,
                    business_unit: {
                        id: 0,
                        name: '',
                    },
                    mixable_type: null,
                    mixable_id: '',
                    mixable: {
                        id: 0,
                        name: '',
                    },
                    products: [],
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalMix: this.modal,
            isEdit: false,
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            loadingProducts: false,
            productsLoaded: false,
            tabIndex: 0,
            productName: '',
            mixableType: null,
            typeChanged: false,
            productsParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        modalMix: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },

            external_code: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(255),
            },

            business_unit: {
                id: {
                    required,
                    numeric,
                    minValue: minValue(1)
                },
            },

            products: {
                $each: {
                    id: {
                        required,
                        numeric,
                        minValue: minValue(1)
                    },
                },
            },

            mixable: {
                id: {
                    required: requiredIf(function () {
                        return this.modalMix.mixable_type !== null
                    }),
                },
            },
        },
    },

    computed: {
        isMixCreated() {
            return this.modalMix.id > 0
        },
        i18nTitleModal() {
            if (this.isMixCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nMix}`
                }
                return this.modalMix.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_mix')
            }
        },
        i18nExternalCode() {
            return this.getI18n(i18nCommon, 'external_code')
        },
        i18nMix() {
            return this.getI18n(i18nKey, 'TITLES.mix')
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nPosRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
        i18nPosChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nPosChannel() {
            return this.getI18n('POS', 'TITLES.channel')
        },
        i18nType() {
            return this.getI18n(i18nCommon, 'type')
        },
        i18nMixable() {
            const selectedType = this.mixableTypes.find(
                (type) => type.value === this.modalMix.mixable_type
            )
            return selectedType ? selectedType.text : ''
        },
        invalidMixable() {
            const selectedType = this.mixableTypes.find(
                (type) => type.value === this.modalMix.mixable_type
            )
            return selectedType ? selectedType.invalid_message : ''
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'info',
                modifier: 2,
            })
        },
        i18nProducts() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'product',
                modifier: 2,
            })
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'pos',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isMixCreated ? 'edited' : 'created'
        },
    },
    watch: {
        productName(value) {
            if (value) {
                const index = this.modalMix.products.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.modalMix.products.push(value)
                }
                this.productName = ''
                this.$refs.productMultiselect.$el.focus()
            }
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.modalMix.$touch()
                    if (this.$v.modalMix.$invalid) {
                        event.preventDefault();
                    }
                }
            }
            if (newTabIndex === 1) {
                this.loadProducts()
            }
        },
        changeMixableType(type) {
            this.typeChanged = true
            this.modalMix.mixable_type = type ? type.value : null
            this.modalMix.mixable_id = null
            this.modalMix.mixable = {}
            this.typeChanged = false
        },
        changeMixableId(e) {
            this.modalMix.mixable_id = e ? e.id : null
            this.clearResponseError('mixable', 'modalMix')
        },
        changeBusinessUnitId(e) {
            this.modalMix.business_unit_id = e ? e.id : null
            this.clearResponseError('business_unit', 'modalMix')
        },
        async loadProducts() {
            if (this.productsLoaded || this.loadingProducts) {
                return
            }

            if (!this.isMixCreated) {
                this.productsLoaded = true
                return
            }

            this.loadingProducts = true
            await ProductsService.fetchAll({
                mix_id: this.modalMix.id,
                pagination: false,
            }).then((response) => {
                this.modalMix.products = response.data.data
                this.productsLoaded = true
            })
            this.loadingProducts = false
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.editLoading = false
            this.submitLoading = false
            this.deleteLoading = false
            this.loadingProducts = false
            this.productsLoaded = false
            this.modalMix = this.modal
            this.mixableType = this.mixableTypes.find((type) => type.value === this.modalMix.mixable_type) ?? null
            this.typeChanged = false
            this.productName = ''
            this.tabIndex = 0
            this.$refs.formModal.show()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        replaceModalData(data) {
            Object.assign(this.modalMix, data)
        },
        handleEdit() {
            this.editLoading = true

            this.loadProducts()
            this.isEdit = true
            this.tabIndex = 0

            this.editLoading = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteMix)
        },
        async deleteMix(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalMix.$touch()
            this.deleteLoading = true
            const response = await MixesService.delete(this.modalMix.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nMix, 'deleted', 3)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        handleOk() {
            this.$v.modalMix.$touch()
            if (!this.$v.modalMix.$invalid && !this.submitLoading) {
                if (this.modalMix.products.length <= 0) {
                    this.warningFeedback(this.i18nMix, this.getI18n(this.i18nKey, 'MESSAGES.product_is_required'))
                    this.tabIndex = 1
                    return;
                }

                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation = this.isMixCreated
                ? MixesService.update(this.modalMix.id, this.modalMix)
                : MixesService.create(this.modalMix)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nMix, this.modalOperation)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nMix, response.data.errors)
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
            this.submitLoading = false
        },
        async handleRemoveProduct(id) {
            const index = this.modalMix.products.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalMix.products.splice(index, 1);
            }
        },
        async handleRemovePos(id) {
            const index = this.modalMix.point_of_sales.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalMix.point_of_sales.splice(index, 1);
            }
        },
    },
}
</script>
