<script>
import VuetableFieldCheckbox from '@src/components/vuetable/VuetableFieldCheckbox.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'
const i18nKey = 'PRODUCTS'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            productFields: [
                {
                    name: VuetableFieldCheckbox,
                    titleClass: 'center aligned',
                    dataClass: 'center aligned',
                    togglable: true,
                },
                {
                    name: 'reference',
                    title: this.getI18n(i18nKey, 'FIELDS.reference'),
                    sortField: 'reference',
                },
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'brand.name',
                    title: this.getI18n('BRANDS', 'TITLES.brand'),
                    sortField: 'brands|brands.name',
                },
                {
                    name: 'category.name',
                    title: this.getI18n(i18nCommon, 'category'),
                    sortField: 'product_categories|product_categories.name',
                },
                {
                    name: 'bar_code',
                    title: this.getI18n(i18nKey, 'FIELDS.bar_code'),
                    sortField: 'bar_code',
                },
                {
                    name: 'min_price',
                    title: this.getI18n(i18nKey, 'FIELDS.min_price'),
                    sortField: 'min_price',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'max_price',
                    title: this.getI18n(i18nKey, 'FIELDS.max_price'),
                    sortField: 'max_price',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'is_category_leader',
                    title: this.getI18n(i18nKey, 'FIELDS.category_leader'),
                    sortField: 'is_category_leader',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'is_competitor',
                    title: this.getI18n(i18nKey, 'FIELDS.competitor'),
                    sortField: 'is_competitor',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'active',
                    title: this.getI18n(i18nCommon, 'active'),
                    sortField: 'active',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'photo',
                    title: this.getI18n('COMMON', 'photo'),
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
}
</script>
