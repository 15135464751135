<script>
import VuetableFieldCheckbox from '@src/components/vuetable/VuetableFieldCheckbox.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main'
import FilterBar from '@components/FilterBar'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import mixesModal from '@views/mixes/mixesModal'
import mixFilterModal from '@views/mixes/mixFilterModal'
import mixProductModal from '@views/mixes/mixProductModal'
import MixesService from '@src/services/MixesService'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import mixTypes from '@src/mixins/mixTypes'
import importModal from '@views/importJob/importJobModal'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import EyeTableButton from '@/src/components/EyeTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import DeleteAllButton from '@/src/components/DeleteAllButton.vue'
import DeleteSelectedButton from '@/src/components/DeleteSelectedButton.vue'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'MIXES'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        ConfirmationFormModal,
        FeedbackModal,
        mixesModal,
        mixFilterModal,
        mixProductModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        FilterLabelButton,
        EyeTableButton,
        MoreTableButton,
        DeleteAllButton,
        DeleteSelectedButton,
    },
    mixins: [
        swalFeedback,
        pagination,
        filterVuetable,
        vuetableFormatters,
        mixTypes,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            additionalParameters: {
                with: ['businessUnit', 'mixable'],
            },
            deleteType: 'delete_selected',
            deleteSelectedLoading: false,
            deleteAllLoading: false,
            modalIsEdit: false,
            modal: {
                id: 0,
                name: '',
                external_code: '',
                business_unit_id: null,
                business_unit: {
                    id: 0,
                    name: '',
                },
                mixable_type: null,
                mixable_id: '',
                mixable: {
                    id: 0,
                    name: '',
                },
                products: [],
            },
            data: [],
            perPage: 10,
            fields: [
                {
                    name: VuetableFieldCheckbox,
                    titleClass: 'center aligned',
                    dataClass: 'center aligned',
                    togglable: true,
                },
                {
                    name: 'external_code',
                    title: this.getI18n(i18nCommon, 'external_code'),
                    sortField: 'external_code',
                },
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'business_unit.name',
                    title: this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit'),
                    sortField: 'business_unit|business_unit.name',
                },
                {
                    name: 'mixable_type',
                    title: this.getI18n(i18nCommon, 'type'),
                    sortField: 'mixable_type',
                    formatter: (value) => {
                        return this.typeToText(value)
                    },
                },
                {
                    name: 'mixable.name',
                    title: this.getI18n(i18nCommon, 'type_relation'),
                    formatter: (value) => {
                        return this.nullableField(value)
                    },
                },
                {
                    name: 'products',
                    title: this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    })
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            css: {},
            filterText: '',
            filters: {
                mixable_type: null,
                mixables: [],
                business_units: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.mix',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewMix() {
            return this.getI18n(i18nKey, 'BUTTONS.new_mix')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                with: ['businessUnit', 'mixable', 'products'],
                search: '',
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText};external_code:${searchText};businessUnit.name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },

    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                with: ['businessUnit', 'mixable'],
                mixable_type: this.filters.mixable_type,
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                mixable_id: this.filters.mixables.map((mixable) => mixable.id),
            })
        },
        cleanFilter() {
            Object.assign(this.filters, {
                mixable_type: null,
                mixables: [],
                business_units: [],
            })
            this.additionalParameters = {
                with: ['businessUnit', 'mixable'],
            }
            this.refreshTable()
        },
        fetch(url, params) {
            return MixesService.fetchVuetable(url, params)
        },
        i18nMix(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.mix',
                modifier: modifier,
            })
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                name: '',
                external_code: '',
                business_unit_id: null,
                business_unit: {
                    id: 0,
                    name: '',
                },
                mixable_type: null,
                mixable_id: '',
                mixable: {
                    id: 0,
                    name: '',
                },
                products: [],
            })
        },

        showModal() {
            this.$nextTick(() => this.$refs.mixModal.showModal())
        },

        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        async openModalToEdit(data) {
            this.modalIsEdit = false
            this.replaceModalData(data)
            this.showModal()
        },

        openModalProducts(data) {
            this.$nextTick(() => this.$refs.mixProductModal.showModal(data.id, data.name))
        },

        replaceModalData(data) {
            this.modal = Object.assign(this.modal, data)
            this.modal.business_unit_id = this.modal.business_unit && this.modal.business_unit.id > 0 ? this.modal.business_unit.id : null
            this.modal.mixable_id = this.modal.mixable && this.modal.mixable.id > 0 ? this.modal.mixable.id : null

            Object.assign(this.modal, {
                products: [],
            })

            Object.assign(this.modal.products, data.products)
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.mixFilterModal.showModal(this.filters)
            )
        },

        handleDelete(type) {
            if (type === 'delete_selected' && this.deleteSelectedLoading) {
                return
            } else if (type === 'delete_all' && this.deleteAllLoading) {
                return
            }

            this.deleteType = type
            if (type === 'delete_selected') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedSelected)
            }
            else if (type === 'delete_all') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedAllFiltered)
            }
        },

        async deleteMix(deleteFunction) {
            const response = await deleteFunction
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.feedbackModal.showModal(statusCode, response.data)
                this.refreshTable()
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
            this.deleteSelectedLoading = false
            this.deleteAllLoading = false
        },

        async deletedSelected(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteSelectedLoading = true
            await this.deleteMix(
                MixesService.deleteMultiple({
                    mix_id: this.$refs.vuetable.$refs.vuetable.selectedTo,
                })
            )
        },

        async deletedAllFiltered(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteAllLoading = true
            await this.deleteMix(
                MixesService.deleteMultiple(
                    this.additionalParameters
                )
            )
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText" />
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'MIX')"
                            :title="i18nNewMix"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="mix"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <b-row>
                    <div class="col-sm-6">
                        <PageHeader :title="i18nMix(2)" />
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <delete-selected-button
                                v-if="$can('DELETE', 'MIX')"
                                :busy="deleteSelectedLoading"
                                :disabled="deleteAllLoading"
                                @onClick="handleDelete('delete_selected')"
                            />
                            <delete-all-button
                                v-if="$can('DELETE', 'MIX')"
                                :busy="deleteAllLoading"
                                :disabled="deleteSelectedLoading"
                                @onClick="handleDelete('delete_all')"
                            />
                        </div>
                    </div>
                </b-row>
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="products" slot-scope="props">
                            <eye-table-button @onClick="openModalProducts(props.rowData)" />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nMix(2)"
            :operation="deleteType"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nMix(2)"
            :operation="deleteType"
        />
        <mixes-modal
            ref="mixModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="reloadTable"
        />
        <import-modal
            ref="importModal"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TITLES.mix',
                    modifier: 2,
                })
            "
            :csv-type="'mix'"
            :available-types="['mix', 'mix_has_product']"
        />
        <mix-filter-modal
            ref="mixFilterModal"
            :handle-submit="filter"
        />
        <mix-product-modal
            ref="mixProductModal"
        />
    </Layout>
</template>
