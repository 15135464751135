<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            posChildrenActionFields: [
                {
                    key: 'external_code',
                    label: this.getI18n(i18nCommon, 'external_code'),
                    sortable: true,
                },
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.pos'),
                    sortable: true,
                },
                {
                    key: 'revenue',
                    label: this.getI18n(i18nKey, 'FIELDS.revenue'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
}
</script>
