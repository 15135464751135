import BaseService from '@src/services/BaseService'

class ProductsService extends BaseService {
    constructor() {
        super('products')
    }

    deleteMultiple(params) {
        return this.axios.post(`${this.getUrl()}/delete-multiple`, params)
    }
}

export default new ProductsService()
