<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitsMultiselect"
                                v-model="filterModal.business_units"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="filterParameters"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nType"
                            label-for="filter-mixable-type"
                            class="label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                        >
                            <translated-multiselect
                                id="filter-mixable-type"
                                v-model="mixableType"
                                :options="mixableTypesFilter"
                                track-by="value"
                                label="text"
                                :preselect-first="true"
                                @input="changeMixableType"
                            />
                        </b-form-group>
                        <b-form-group
                            v-show="filterModal.mixable_type"
                            :label="i18nMixable"
                            class="required label-pdv"
                            label-for="filter-mixable"
                        >
                            <multiSelectWithService
                                v-show="filterModal.mixable_type === 'point_of_sale'"
                                :id="'filter-mixable'"
                                ref="mixableMultiselect"
                                v-model="filterModal.mixables"
                                :service="'pos'"
                                :searchable="true"
                                :multiple="true"
                            />
                            <multiSelectWithService
                                v-show="filterModal.mixable_type === 'point_of_sale_chain'"
                                :id="'filter-mixable'"
                                ref="mixableMultiselect"
                                v-model="filterModal.mixables"
                                :service="'pos-chains'"
                                :searchable="true"
                                :multiple="true"
                            />
                            <multiSelectWithService
                                v-show="filterModal.mixable_type === 'point_of_sale_channel'"
                                :id="'filter-mixable'"
                                ref="mixableMultiselect"
                                v-model="filterModal.mixables"
                                :service="'pos-channels'"
                                :searchable="true"
                                :multiple="true"
                            />
                            <multiSelectWithService
                                v-show="filterModal.mixable_type === 'point_of_sale_regional'"
                                :id="'filter-mixable'"
                                ref="mixableMultiselect"
                                v-model="filterModal.mixables"
                                :service="'pos-regionals'"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import mixTypes from '@src/mixins/mixTypes'
import multiSelectWithService from '@src/components/multiSelectWithService'
import translatedMultiselect from '@src/components/translatedMultiselect'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'

const i18nKey = 'MIXES'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        multiSelectWithService,
        translatedMultiselect,
        SaveButton,
        ClearFilterButton
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        mixTypes
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            mixableType: null,
        }
    },
    validations: {},
    computed: {
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nType() {
            return this.getI18n(i18nCommon, 'type')
        },
        i18nMixable() {
            const selectedType = this.mixableTypes.find(
                (type) => type.value === this.filterModal.mixable_type
            )
            return selectedType ? selectedType.text : ''
        },
    },
    methods: {
        changeMixableType(type) {
            this.filterModal.mixable_type = type ? type.value : null
            this.filterModal.mixables = []
        },
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.mixableType = null
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                mixable_type: null,
                mixables: [],
                business_units: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
