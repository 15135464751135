<script>
export default {
    data() {
        return {
            mixableTypes: [
                {
                    value: null,
                    text: this.typeToText('null'),
                    invalid_message: this.getI18n(
                        'ERROR_CODES',
                        'invalid_business_unit'
                    ),
                },
                {
                    value: 'point_of_sale',
                    text: this.typeToText('point_of_sale'),
                    invalid_message: this.getI18n('ERROR_CODES', 'invalid_pos'),
                },
                {
                    value: 'point_of_sale_chain',
                    text: this.typeToText('point_of_sale_chain'),
                    invalid_message: this.getI18n(
                        'ERROR_CODES',
                        'invalid_chain'
                    ),
                },
                {
                    value: 'point_of_sale_channel',
                    text: this.typeToText('point_of_sale_channel'),
                    invalid_message: this.getI18n(
                        'ERROR_CODES',
                        'invalid_channel'
                    ),
                },
                {
                    value: 'point_of_sale_regional',
                    text: this.typeToText('point_of_sale_regional'),
                    invalid_message: this.getI18n(
                        'ERROR_CODES',
                        'invalid_regional'
                    ),
                },
            ],
            mixableTypesFilter: [
                {
                    value: null,
                    text: this.getI18n('COMMON', 'all'),
                },
                {
                    value: 'point_of_sale',
                    text: this.typeToText('point_of_sale'),
                },
                {
                    value: 'point_of_sale_chain',
                    text: this.typeToText('point_of_sale_chain'),
                },
                {
                    value: 'point_of_sale_channel',
                    text: this.typeToText('point_of_sale_channel'),
                },
                {
                    value: 'point_of_sale_regional',
                    text: this.typeToText('point_of_sale_regional'),
                },
            ]
        }
    },
    methods: {
        typeToText(value) {
            return this.getI18n('MIXES', 'TYPES.' + value)
        },
    },
}
</script>
