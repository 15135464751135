<template>
    <a
        href="javascript: void(0);"
        @click="$emit('onClick')">
        <i class="fe-eye icon-blue"/>
    </a>
</template>

<script>
export default {}
</script>
